import { useEffect, useState } from "react";
import Link from "next/link";

const LineValueStats = ({ league = "ncaa", page = "all", showMore = false }) => {
  const [seasonRecord, setSeasonRecord] = useState(null);
  const [bestBetsRecord, setBestBetsRecord] = useState(null);
  useEffect(() => {
    // if (page === "best-bets") {
    fetch(
      `${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/${
        league === "ncaa" ? "college_mens" : league
      }/results-summary-best-bets`
    )
      .then((response) => response.json())
      .then((response) => {
        const data = response.data;

        let allUnits = data.find((d) => d.split === "all" && d.value_type === "all");
        let last7Units = data.find((d) => d.split === "Last 7" && d.value_type === "all");
        let last30Units = data.find((d) => d.split === "Last 30" && d.value_type === "all");
        let yesterday = data.find((d) => d.split === "Yesterday" && d.value_type === "all");

        setBestBetsRecord({
          yesterday: { ...yesterday, Units: yesterday?.Units || 0 },
          all: { ...allUnits, Units: allUnits?.Units || 0 },
          last7: { ...last7Units, Units: last7Units?.Units || 0 },
          last30: { ...last30Units, Units: last30Units?.Units || 0 },
        });
      })
      .catch((err) => {
        console.error("error==>>", err);
      });
    // } else {
    fetch(
      `${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/${league === "ncaa" ? "college_mens" : league}/results-summary`
    )
      .then((response) => response.json())
      .then((response) => {
        const data = response.data;

        let allUnits = data.find((d) => d.split === "all" && d.value_type === "all");
        let last7Units = data.find((d) => d.split === "Last 7" && d.value_type === "all");
        let last30Units = data.find((d) => d.split === "Last 30" && d.value_type === "all");
        let yesterday = data.find((d) => d.split === "Yesterday" && d.value_type === "all");

        setSeasonRecord({
          yesterday: { ...yesterday, Units: yesterday?.Units || 0 },
          all: { ...allUnits, Units: allUnits?.Units || 0 },
          last7: { ...last7Units, Units: last7Units?.Units || 0 },
          last30: { ...last30Units, Units: last30Units?.Units || 0 },
        });
      })
      .catch((err) => {
        console.error("error==>>", err);
      });
    // }
  }, []);
  if (seasonRecord == null || bestBetsRecord == null) {
    return <></>;
  } else {
    return (
      <>
        {page === "best-bets" || page == "all" ? (
          <>
            <div className="row line-value-stats">
              <div className={`${showMore ? "col-md-10" : "col-md-8"} col-sm-12 text-center heading m-auto`}>
                Best Bets Results
              </div>
            </div>
            <div className="row line-value-stats">
              <div className="col-md-2 col-sm-6 col-6 text-center box">
                <h4 style={{ color: bestBetsRecord.yesterday.Units > 0 ? "green" : "red" }}>
                  {bestBetsRecord.yesterday.Units > 0 ? "+" : ""}
                  {bestBetsRecord.yesterday.Units?.toFixed(1)}u
                </h4>
                <h5>
                  {bestBetsRecord.yesterday.Wins}
                  {"-"}
                  {bestBetsRecord.yesterday.Losses}
                  {"-"}
                  {bestBetsRecord.yesterday.Pushes}{" "}
                  {"(" +
                    (
                      (bestBetsRecord.yesterday.Wins /
                        (bestBetsRecord.yesterday.Wins + bestBetsRecord.yesterday.Losses)) *
                      100
                    ).toFixed(0) +
                    "%)"}
                </h5>
                <span style={{ color: "black" }}> Yesterday</span>
              </div>
              <div className="col-md-2 col-sm-6 col-6 text-center box">
                <h4 style={{ color: bestBetsRecord.last7.Units > 0 ? "green" : "red" }}>
                  {bestBetsRecord.last7.Units > 0 ? "+" : ""}
                  {bestBetsRecord.last7.Units?.toFixed(1)}u
                </h4>
                <h5>
                  {bestBetsRecord.last7.Wins}
                  {"-"}
                  {bestBetsRecord.last7.Losses}
                  {"-"}
                  {bestBetsRecord.last7.Pushes}{" "}
                  {"(" +
                    (
                      (bestBetsRecord.last7.Wins / (bestBetsRecord.last7.Wins + bestBetsRecord.last7.Losses)) *
                      100
                    ).toFixed(0) +
                    "%)"}
                </h5>
                <span style={{ color: "black" }}> Last 7 days</span>
              </div>
              <div className="col-md-2 col-sm-6 col-6 text-center box">
                <h4 style={{ color: bestBetsRecord.last30.Units > 0 ? "green" : "red" }}>
                  {bestBetsRecord.last30.Units > 0 ? "+" : ""}
                  {bestBetsRecord.last30.Units?.toFixed(1)}u
                </h4>
                <h5>
                  {bestBetsRecord.last30.Wins}
                  {"-"}
                  {bestBetsRecord.last30.Losses}
                  {"-"}
                  {bestBetsRecord.last30.Pushes}{" "}
                  {"(" +
                    (
                      (bestBetsRecord.last30.Wins / (bestBetsRecord.last30.Wins + bestBetsRecord.last30.Losses)) *
                      100
                    ).toFixed(0) +
                    "%)"}
                </h5>
                <span style={{ color: "black" }}> Last 30 days</span>
              </div>
              <div className="col-md-2 col-sm-6 col-6 text-center box">
                <h4 style={{ color: bestBetsRecord.all.Units > 0 ? "green" : "red" }}>
                  {bestBetsRecord.all.Units > 0 ? "+" : ""}
                  {bestBetsRecord.all.Units?.toFixed(1)}u
                </h4>
                <h5>
                  {bestBetsRecord.all.Wins}
                  {"-"}
                  {bestBetsRecord.all.Losses}
                  {"-"}
                  {bestBetsRecord.all.Pushes}{" "}
                  {"(" +
                    ((bestBetsRecord.all.Wins / (bestBetsRecord.all.Wins + bestBetsRecord.all.Losses)) * 100).toFixed(
                      0
                    ) +
                    "%)"}
                </h5>
                <span style={{ color: "black" }}> This season</span>
              </div>
              {/* {showMore && (
                <div className="col-md-2 col-sm-6 col-6 text-center box">
                  <h4>
                    📊 <Link href={`/${league === "college_mens" ? "ncaa" : league}/model-results`}>More Results</Link>
                  </h4>
                </div>
              )} */}
            </div>
          </>
        ) : null}
        {page === "model-results" || page == "all" ? (
          <>
            <div className="row line-value-stats">
              <div className={`col-md-10 col-sm-12 text-center heading m-auto`}> Model Results</div>
            </div>
            <div className="row line-value-stats">
              <div className="col-md-2 col-sm-6 col-6 text-center box">
                <h4 style={{ color: seasonRecord.yesterday.Units > 0 ? "green" : "red" }}>
                  {seasonRecord.yesterday.Units > 0 ? "+" : ""}
                  {seasonRecord.yesterday.Units?.toFixed(1)}u
                </h4>
                <h5>
                  {seasonRecord.yesterday.Wins}
                  {"-"}
                  {seasonRecord.yesterday.Losses}
                  {"-"}
                  {seasonRecord.yesterday.Pushes}{" "}
                  {"(" +
                    (
                      (seasonRecord.yesterday.Wins / (seasonRecord.yesterday.Wins + seasonRecord.yesterday.Losses)) *
                      100
                    ).toFixed(0) +
                    "%)"}
                </h5>
                <span style={{ color: "black" }}> Yesterday</span>
              </div>
              <div className="col-md-2 col-sm-6 col-6 text-center box">
                <h4 style={{ color: seasonRecord.last7.Units > 0 ? "green" : "red" }}>
                  {seasonRecord.last7.Units > 0 ? "+" : ""}
                  {seasonRecord.last7.Units?.toFixed(1)}u
                </h4>
                <h5>
                  {seasonRecord.last7.Wins}
                  {"-"}
                  {seasonRecord.last7.Losses}
                  {"-"}
                  {seasonRecord.last7.Pushes}{" "}
                  {"(" +
                    ((seasonRecord.last7.Wins / (seasonRecord.last7.Wins + seasonRecord.last7.Losses)) * 100).toFixed(
                      0
                    ) +
                    "%)"}
                </h5>
                <span style={{ color: "black" }}> Last 7 days</span>
              </div>
              <div className="col-md-2 col-sm-6 col-6 text-center box">
                <h4 style={{ color: seasonRecord.last30.Units > 0 ? "green" : "red" }}>
                  {seasonRecord.last30.Units > 0 ? "+" : ""}
                  {seasonRecord.last30.Units?.toFixed(1)}u
                </h4>
                <h5>
                  {seasonRecord.last30.Wins}
                  {"-"}
                  {seasonRecord.last30.Losses}
                  {"-"}
                  {seasonRecord.last30.Pushes}{" "}
                  {"(" +
                    (
                      (seasonRecord.last30.Wins / (seasonRecord.last30.Wins + seasonRecord.last30.Losses)) *
                      100
                    ).toFixed(0) +
                    "%)"}
                </h5>
                <span style={{ color: "black" }}> Last 30 days</span>
              </div>
              <div className="col-md-2 col-sm-6 col-6 text-center box">
                <h4 style={{ color: seasonRecord.all.Units > 0 ? "green" : "red" }}>
                  {seasonRecord.all.Units > 0 ? "+" : ""}
                  {seasonRecord.all.Units?.toFixed(1)}u
                </h4>
                <h5>
                  {seasonRecord.all.Wins}
                  {"-"}
                  {seasonRecord.all.Losses}
                  {"-"}
                  {seasonRecord.all.Pushes}{" "}
                  {"(" +
                    ((seasonRecord.all.Wins / (seasonRecord.all.Wins + seasonRecord.all.Losses)) * 100).toFixed(0) +
                    "%)"}
                </h5>
                <span style={{ color: "black" }}> This season</span>
              </div>
              <div className="col-md-2 col-sm-6 col-6 text-center box">
                <h4 style={{ color: seasonRecord.all.Units + 223 > 0 ? "green" : "red" }}>
                  {seasonRecord.all.Units + 223 > 0 ? "+" : ""}
                  {(seasonRecord.all?.Units + 223)?.toFixed(1)}u
                </h4>
                <h5>
                  {seasonRecord.all.Wins + 3230}
                  {"-"}
                  {seasonRecord.all.Losses + 2735}
                  {"-"}
                  {seasonRecord.all.Pushes + 83}{" "}
                  {"(" +
                    (
                      ((seasonRecord.all.Wins + 3230) /
                        (seasonRecord.all.Wins + 3230 + seasonRecord.all.Losses + 2735)) *
                      100
                    ).toFixed(0) +
                    "%)"}
                </h5>
                <span style={{ color: "black" }}> All Time</span>
              </div>
            </div>
            {/* <div className="row d-flex justify-center">
              {showMore && (
                <div className="col-md-2 col-sm-6 col-6 text-center  box">
                  <h4>
                    <Link
                      style={{ whiteSpace: "nowrap" }}
                      href={`/${league === "college_mens" ? "ncaa" : league}/model-results`}
                    >
                      See More Results ➡️
                    </Link>
                  </h4>
                </div>
              )}
            </div> */}
          </>
        ) : null}
      </>
    );
  }
};

export default LineValueStats;
